<!-- Iremos colocar aqui todos os cadastros complementares do sisteam. para deixar a equipe mais independete. 
    Ex: Conselho, Modalidade, -->

<template>
  <v-row>
    <v-col>
      <v-data-table :loading="isLoading('get:companies')" loading-text="Carregando Informações...." :headers="headers" :items="filteredData" class="fixed-header-table" fixed-header  height="700px" :items-per-page="-1">
        <template v-slot:top>
          <v-row class="mt-2">
            <v-col cols="12">
              <v-row>
                <v-col cols="5">
                  <v-text-field dense outlined v-model="search" label="Pesquisar" class="mx-0" append-icon="mdi-magnify"
                    clearable />
                </v-col>
                <v-col class="text-end">
                  <v-badge left bordered overlap :value="activeFilters.length > 0" :content="activeFilters.length">
                    <v-btn class="mr-2 elevation-0" text v-on:click="showFilters = !showFilters">
                      <v-icon v-if="!showFilters" left>
                        mdi-filter
                      </v-icon>
                      <v-icon v-else left>
                        mdi-filter-off
                      </v-icon>
                      Filtros
                    </v-btn>
                  </v-badge>
                  <v-btn class="mr-4 elevation-0" text @click="atualizar">
                    <v-icon left>
                      mdi-reload
                    </v-icon>
                    atualizar
                  </v-btn>
                  <v-btn color="success" class="elevation-0">
                    <!-- Retiramos o comando para incluir nova empresa "@click="dialog.create = true"".  -->
                    <v-icon left>
                      mdi-plus
                    </v-icon>
                    Novo
                  </v-btn>
                </v-col>
              </v-row>
              <v-expand-transition>
                <v-card outlined v-show="showFilters" dense>
                  <h4 class="ml-4 mt-4">Filtrar por</h4>
                  <v-row dense class="py-2 px-4">
                    <v-col cols="2">
                      <v-select hide-details :items="statusOptions" label="Status" item-text="label" item-value="value"
                        v-model="filters.is_active.value" outlined dense />
                    </v-col>
                  </v-row>
                  <v-card-actions>
                    <v-row dense>
                      <v-col class="text-end">
                        <v-btn color="error" text elevation="0" @click="clearFilters">
                          Limpar filtros
                        </v-btn>
                        <v-btn color="success" class="ml-2" elevation="0" @click="applyFilters">
                          Aplicar filtros
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-card-actions>
                </v-card>
              </v-expand-transition>
            </v-col>
          </v-row>
        </template>
        <template v-slot:[`item.ativo`]="{ item }">
          <v-chip class="ma-2" :color="item.ativo ? 'blue' : 'grey'">
            <span class="white--text">{{ item.ativo ? 'Ativo' : 'Inativo' }}</span>
          </v-chip>
        </template>
        <template v-slot:[`item.config`]="{ item }">
            <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                    <v-btn icon v-bind="attrs" v-on="on" @click="onSelectRow(item, 'update')">
                        <v-icon color="green">mdi-pencil</v-icon>
                    </v-btn>
                </template>
                <span>Clique para editar</span>
            </v-tooltip>
        </template>

      </v-data-table>
    </v-col>
    <v-dialog v-if="dialog.create" v-model="dialog.create">
      <v-card>
        <v-card-title class="blod_color">
          Nova empresa:
          <v-spacer></v-spacer>
          <v-btn icon @click="dialog.create = false">
            <v-icon>
              mdi-close
            </v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <FormCompany :currentItem="newItem" :loading="isLoading('post:companies')" :onSubmit="createNewCompany" />
        </v-card-text>
      </v-card>
    </v-dialog>
    <FullScreenDialog v-model="dialog.update" scrollable>
      <v-card>
        <v-card-title class="blod_color">
          Editar empresa: {{ selectedRow.nome_fantasia }} - ID: {{ selectedRow.id }}
          <v-spacer></v-spacer>
          <v-btn icon @click="dialog.update = false">
            <v-icon>
              mdi-close
            </v-icon>
          </v-btn>
        </v-card-title>
        <v-divider class="mb-4" />
        <v-card-text>
          <FormCompany :currentItem="selectedRow" :loading="isLoading('update:companies')"
            :onSubmit="updateCurrentCompany" />
        </v-card-text>


      </v-card>
    </FullScreenDialog>
  </v-row>
</template>

<script>
import api from '../../http';
import UtilsFunc from '../../service/utilsFunc';
import FullScreenDialog from "../FullscreenDialog.vue";
import FormCompany from "../cadastros/componentes/empresas/FormCompany.vue"
import '../../assets/css/styles.css'
import { mapActions } from 'vuex';

const { withCRUDUtils } = UtilsFunc;

export default {
  data: () => withCRUDUtils({ 
    newItem: { ativo: true, },
    headers: [
      { text: "Editar empresa", align: "center", sortable: false, value: "config" },
      { text: "ID", align: "center", value: "id" },
      { text: "Nome fantasia", value: "nome_fantasia", width: 200 },
      { text: "Razão social", value: "razao_social" },
      { text: "CNPJ", align: "center", value: "cnpj", width: 170 },
      { text: "Tipo de empresa", align: "center", value: "tipo.nome" },
      { text: "CNES", align: "center", value: "cnes" },
      { text: "Médico responsável", align: "center", value: "medico_responsavel" },
      { text: "Status", value: "ativo" },
    ],
    showFilters: false,
    filters: { is_active: { value: true, compareType: 'equal', prop: 'ativo' } },
    statusOptions: [{ label: 'Sim', value: 1 }, { label: "Não", value: 0 }],
    activeFilters: [],
    currentTab: 0
  }),
  methods: {
    ...mapActions(['updateCompany', 'updateSystemColor']),
    async getCompanies() {
      const LOADING_NAME = "get:companies";
      this.setLoading(LOADING_NAME);
      try {
        const { data } = await api.get("/multi_empresas/empresas/");
        this.updateCompany(data[0])

        const themes = {
          themes: this.$vuetify.theme.themes,
          color: data[0].cor
        }
        this.updateSystemColor(themes)

        this.tableData = data;
        this.applyFilters()
        // console.log(this.tableData)
      }
      catch (error) {
        this.$errorHandler(error);
      }
      finally {
        this.setLoading(LOADING_NAME, true);
      }
    },
    async createNewCompany({ certificado, carimbo, logo, ...fields }) {
      const LOADING_NAME = "post:companies";
      this.setLoading(LOADING_NAME);
      try {
        const createdCompany = await api.post("/multi_empresas/empresas/", fields);
        const form = new FormData()
        form.append("certificado", certificado)
        form.append("carimbo", carimbo)
        form.append("logo", logo)
        await api.put(`/multi_empresas/empresa-files/${createdCompany.data.id}/`, form);
        await this.getCompanies();
        this.newItem = { ativo: true };
        this.dialog.create = false;
        this.$toast.success("Empresa inserida com sucesso!");
      } catch (error) {
        this.$errorHandler(error);
        this.$toast.error(`Desculpe. Algo deu errado. Erro -> ${error}`)
      } finally {
        this.setLoading(LOADING_NAME, true);
      }
    },
    async updateCurrentCompany({ certificado, carimbo, logo, ...fields }) {
      const LOADING_NAME = "update:companies";
      this.setLoading(LOADING_NAME);
      try {
        const updatedCompany = await api.put(`/multi_empresas/empresa/${fields.id}/`, fields);
        const form = new FormData()
        if (certificado && typeof certificado !== 'string') {
          form.append("certificado", certificado)
        }
        if (carimbo === null) {
          form.append("carimbo", "")
        } else if (typeof carimbo !== 'string') {
          form.append("carimbo", carimbo)
        }
        if (logo === null) {
          form.append("logo", "")
        } else if (typeof logo !== 'string') {
          form.append("logo", logo)
        }
        form.append("ativo", fields.ativo)
        form.append("visita_mes", fields.visita_mes)
        form.append("active_geo", fields.active_geo)


        await api.put(`/multi_empresas/empresa-files/${updatedCompany.data.id}/`, form);
        await this.getCompanies();
        this.dialog.update = false;
        this.$toast.success("Empresa atualizada com sucesso!");
      } catch (error) {
        this.$errorHandler(error);
        this.$toast.error(`Desculpe. Algo deu errado. Erro -> ${error}`)
      } finally {
        this.setLoading(LOADING_NAME, true);
      }
    },
    async applyFilters() {
      this.search = "";
      const filters = JSON.parse(JSON.stringify(this.filters));
      this.activeFilters = Object.values(filters).filter(filter => filter.value !== undefined);
    },
    clearFilters() {
      this.search = "";
      Object.keys(this.filters).forEach((prop) => this.filters[prop].value = "");
      this.filters = {
        is_active: { value: true, compareType: "equal", prop: "ativo" }
      };
      this.activeFilters = [{ value: true, compareType: "equal", prop: "ativo" }];
    },
    removeCarimboLogo() {
      this.currentItem.carimbo = null;
      this.currentItem.logo = null;
    },
    atualizar(){
      this.search = "";
      this.clearFilters();
      this.getCompanies();
    },
  },
  mounted() {
    this.getCompanies();
  },
  computed: {
    filteredData() {
      const filterBy = UtilsFunc.createFilter(this.activeFilters)
      return this.tableData.filter(filterBy)
    },
  },
  components: { FormCompany, FullScreenDialog }
}

</script>

<style scoped>
.table-container {
  max-height: 500px; /* Define a altura máxima para o contêiner da tabela */
  overflow-y: auto;  /* Permite a rolagem vertical */
}

.fixed-header-table thead th {
  background-color: white; /* Cor de fundo do cabeçalho para que ele permaneça visível */
  position: sticky;
  top: 0;
  z-index: 1;
}
</style>

